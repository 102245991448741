import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./url";

const allApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}` }),
  refetchOnMountOrArgChange: true,
  tagTypes: [
    "JobCard",
    "Clients",
    "Array",
    "JobCard-Count",
    "History",
    "JobCard-Count-Client",
    "User"
  ], //refresh when it innvalidates
  endpoints(build) {
    return {
      fetchJobcards: build.query({
        query: ({ page, stage, date, client_filter, stage_filter }) => {
          console.log(
            "lion",
            stage === "Admin" || stage === "Designing" ||stage==="Out_Station"
              ? ""
              : stage !== null || stage !== undefined
              ? stage
              : "s"
          );
          return {
            url: `/api/job-card/?page=${page}${
              stage === "Admin" || stage === "Designing" || stage === undefined||stage==="Out_Station"
                ? stage==="Out_Station"?"&stage=Cutting&stage=Stitching&stage=Secondary&stage=Packaging":""
                : `&stage=${stage}`
            }${date ? date : ""}${stage_filter}${client_filter}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.results?.length
            ? [
                ...result?.results?.map(({ id }) => ({ type: "JobCard", id })),
                "JobCard",
              ]
            : ["JobCard"],
      }),
      fetchJobcardsHistory: build.query({
        query: ({ date }) => {
         if(date?.length>1){

          return {
            url: `/api/job-card-history/?${date ? date : ""}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        }
        },
        providesTags: (result = [], error, arg) =>
          result?.results?.length
            ? [
                ...result?.results?.map(({ id }) => ({ type: "History", id })),
                "History",
              ]
            : ["History"],
      }),
      fetchJobcardsCount: build.query({
        query: ({ date }) => {
          return {
            url: `/api/job-card/get_stage_clothing_card_count/?${date}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [
                ...result?.map(({ id }) => ({ type: "JobCard-Count", id })),
                "JobCard-Count",
              ]
            : ["JobCard-Count"],
      }),
      fetchUsers: build.query({
        query: () => {
          return {
            url: `/basicauth/customer-user/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [
                ...result?.map(({ id }) => ({ type: "User", id })),
                "User",
              ]
            : ["User"],
      }),
      fetchJobcardsCountClient: build.query({
        query: () => {
          return {
            url: `/api/job-card/get_client_jobcard_count/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [
                ...result?.map(({ id }) => ({
                  type: "JobCard-Count-Client",
                  id,
                })),
                "JobCard-Count-Client",
              ]
            : ["JobCard-Count-Client"],
      }),
      fetchJobcardsCountPerYear: build.query({
        query: () => {
          return {
            url: `/api/job-card/month_wise_job_card_counts/?year=2024`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [
                ...result?.map(({ id }) => ({
                  type: "JobCard-Count-Year",
                  id,
                })),
                "JobCard-Count-Year",
              ]
            : ["JobCard-Count-Year"],
      }),
      getJobcards: build.query({
        query: ({ id }) => {
          if (id) {
            return {
              url: `/api/job-card/${id}/`,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                Accept: "application/json",
              },
            };
          }
        },
      }),
      getReport: build.query({
        query: ({ id }) => {
          return {
            url: `/api/job-card-history/${id}/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
      }),
      createJobCard: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/job-card/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",

              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "JobCard", id: arg.id },
          { type: "JobCard-Count" },
          { type: "JobCard-Count-Client" },
        ],
      }),
      updateJobCard: build.mutation({
        query: ({ createJobcardData, id }) => {
          return {
            url: `/api/job-card/${id}/`,
            method: "PATCH",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",

              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "JobCard", id: arg.id },
          { type: "JobCard-Count" },
        ],
      }),
      fetchArray: build.query({
        query: () => {
          return {
            url: `/api/job-card/get_api_array/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Array", id })), "Array"]
            : ["Array"],
      }),
      fetchClients: build.query({
        query: () => {
          return {
            url: `/api/clients/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Clients", id })), "Clients"]
            : ["Clients"],
      }),
      getClient: build.query({
        query: ({id}) => {
          if(id){

          return {
            url: `/api/clients/${id}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        }
        },
      
      }),
    };
  },
});

export const {
  useFetchJobcardsQuery,
  useFetchJobcardsHistoryQuery,
  useFetchClientsQuery,
  useFetchArrayQuery,
  useCreateJobCardMutation,
  useGetJobcardsQuery,
  useUpdateJobCardMutation,
  useGetReportQuery,

  useFetchJobcardsCountQuery,
  useFetchJobcardsCountClientQuery,
  useFetchJobcardsCountPerYearQuery,

  useGetClientQuery,

  useFetchUsersQuery,
} = allApi;

export { allApi };
